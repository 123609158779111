// Contact.tsx
import { Divider } from '@nextui-org/react';
import React from 'react';

const Contact: React.FC = () => {
  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Contact</h2>
      <Divider className="my-4" />
      <p>
        Please reach your representative, <strong>George</strong>, at{' '}
        <strong>
          <a href="mailto:george@airlockpurifierusa.com">
            george@airlockpurifierusa.com{' '}
          </a>
        </strong>
        or <strong>(954) 623-0970</strong>.
      </p>
    </div>
  );
};

export default Contact;
