import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Image,
} from '@nextui-org/react';
import React, { useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

interface ResourceCardProps {
  title: string;
  imageSrc: string;
  downloadUrl: string;
  fileType: string;
}

const ResourceCard: React.FC<ResourceCardProps> = ({
  title,
  imageSrc,
  downloadUrl,
  fileType,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/downloads/${downloadUrl}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error('Failed to download file');
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank'); // Open the file in a new tab
      window.URL.revokeObjectURL(url); // Clean up the object URL
    } catch (error) {
      console.error('Error downloading and opening file:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className="w-full bg-gradient-to-r from-airlock-purple to-gray-800 shadow-lg hover:shadow-xl transition-shadow duration-300">
      <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
        <h4 className="font-bold text-large text-white">{title}</h4>
      </CardHeader>
      <CardBody className="overflow-visible py-2">
        <Image
          alt={title}
          className="object-cover rounded-xl"
          src={imageSrc}
          width="100%"
        />
      </CardBody>
      <CardFooter className="pb-3 pt-2">
        {downloadUrl ? (
          <Button
            color="primary"
            className="w-full bg-white text-airlock-purple font-bold hover:bg-purple-100"
            onClick={handleClick}
            isLoading={isLoading}
          >
            View & Download .{fileType}
          </Button>
        ) : (
          <Button
            color="default"
            className="w-full bg-gray-400 text-white font-bold cursor-not-allowed"
            disabled
          >
            Coming Soon
          </Button>
        )}
      </CardFooter>
    </Card>
  );
};

// ... rest of the Resources component

const Resources: React.FC = () => {
  const resources = [
    {
      title: 'AIRLOCK™ Company Brochure',
      imageSrc: '',
      downloadUrl: 'AIRLOCK_Brochure.pdf',
      fileType: 'pdf',
    },
    {
      title: 'AIRLOCK™ One Sales Sheet - Boat',
      imageSrc: '',
      downloadUrl: 'AIRLOCK_One_Sales_Sheet_Boat.pdf',
      fileType: 'pdf',
    },
    {
      title: 'AIRLOCK™ One Sales Sheet - RV',
      imageSrc: '',
      downloadUrl: 'AIRLOCK_One_Sales_Sheet_RV.pdf',
      fileType: 'pdf',
    },
    {
      title: 'Authorized Reseller Badge - Version A',
      imageSrc: '',
      downloadUrl: 'authorized_reseller_badge_a.png',
      fileType: 'png',
    },
    {
      title: 'Authorized Reseller Badge - Version B',
      imageSrc: '',
      downloadUrl: 'authorized_reseller_badge_b.png',
      fileType: 'png',
    },
    {
      title: 'AIRLOCK™ One Manual - Boat',
      imageSrc: '',
      downloadUrl: 'AIRLOCK_One_Manual_Boat.pdf',
      fileType: 'pdf',
    },
    {
      title: 'AIRLOCK™ One Manual - RV',
      imageSrc: '',
      downloadUrl: 'AIRLOCK_One_Manual_RV.pdf',
      fileType: 'pdf',
    },
  ];

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Resources</h2>
      <Divider className="my-4" />
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 pt-5 md:px-20">
        {resources.map((resource, index) => (
          <ResourceCard key={index} {...resource} />
        ))}
      </div>
    </div>
  );
};

export default Resources;
