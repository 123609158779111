import React, { useEffect, useState } from 'react';
import {
  deleteReseller,
  fetchAllResellers,
  updateResellerInfo,
} from '../../services/resellerService';

import AddResellerModal from './AddResellerModal';
import { Button } from '@nextui-org/react';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import EditResellerModal from './EditResellerModal';
import { ResellerInfo } from '../../types';
import ResellerTable from './ResellerTable';
import { useAuth0 } from '@auth0/auth0-react';

const Admin: React.FC = () => {
  const [resellers, setResellers] = useState<ResellerInfo[]>([]);
  const [formData, setFormData] = useState<Partial<ResellerInfo>>({});
  const [error, setError] = useState<string | null>(null);
  const [isAddResellerModalOpen, setAddResellerModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedResellerId, setSelectedResellerId] = useState<string | null>(
    null
  );
  const [isAdmin, setIsAdmin] = useState(false);

  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const fetchResellers = async (token: string) => {
    try {
      const data = await fetchAllResellers(token);
      setResellers(data);
    } catch (err) {
      console.error(err);
      setError('Could not fetch resellers');
    }
  };

  useEffect(() => {
    const checkAdminRole = async () => {
      if (isAuthenticated && user) {
        const roles = user['https://api.airlock/roles'] || [];
        if (roles.includes('admin')) {
          setIsAdmin(true);
          const token = await getAccessTokenSilently();
          await fetchResellers(token); // Now fetchResellers is defined when called here
        } else {
          setError(
            'Access Denied: You do not have permission to view this page.'
          );
        }
      }
    };
    checkAdminRole();
  }, [isAuthenticated, user, getAccessTokenSilently]);

  if (!isAdmin) {
    return <div>{error || 'Unauthorized access'}</div>;
  }

  const createAuth0User = async ({
    email,
    first_name,
    last_name,
    company_name,
    password,
    shopify_customer_id,
  }: Partial<ResellerInfo>): Promise<string> => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/reseller/create-auth0-user`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            email,
            first_name,
            last_name,
            company_name,
            password,
            shopify_customer_id,
          }),
        }
      );

      if (!response.ok) {
        console.error('Failed to create Auth0 user:', await response.text());
        throw new Error('Failed to create user in Auth0');
      }

      const data = await response.json();
      return data.user_id;
    } catch (error) {
      console.error('Error creating Auth0 user:', error);
      throw new Error('Unable to create Auth0 user');
    }
  };

  const handleSaveClick = async () => {
    const token = await getAccessTokenSilently();
    if (!token) {
      setError('Authentication token not found');
      return;
    }

    try {
      if (selectedResellerId) {
        const updateData = { id: selectedResellerId, ...formData };
        await updateResellerInfo(token, updateData);
        await fetchResellers(token);
      }
      setEditModalOpen(false);
    } catch (err) {
      console.error(err);
      setError('Could not save changes');
    }
  };

  const handleDeleteClick = (id: string) => {
    setSelectedResellerId(id);
    setDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    const token = await getAccessTokenSilently();
    if (!token) {
      setError('Authentication token not found');
      return;
    }

    try {
      if (selectedResellerId) {
        await deleteReseller(token, selectedResellerId);
        setResellers(prev =>
          prev.filter(reseller => reseller.id !== selectedResellerId)
        );
      }
      setDeleteModalOpen(false);
    } catch (err) {
      console.error(err);
      setError('Could not delete reseller');
    }
  };

  const openEditModal = (reseller: ResellerInfo) => {
    setFormData(reseller);
    setSelectedResellerId(reseller.id);
    setEditModalOpen(true);
  };

  const openAddResellerModal = () => {
    setAddResellerModalOpen(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="w-full">
      <h2 className="text-2xl font-bold mb-4">Reseller Management</h2>
      <Button
        color="primary"
        className="bg-gradient-to-r from-gray-800 to-airlock-purple rounded-2xl mb-5"
        onClick={openAddResellerModal}
      >
        Add New Reseller
      </Button>
      {error && <p className="text-red-500">{error}</p>}
      <ResellerTable
        resellers={resellers}
        onEdit={openEditModal}
        onDelete={handleDeleteClick}
      />
      <EditResellerModal
        isOpen={isEditModalOpen}
        onClose={() => setEditModalOpen(false)}
        onSave={handleSaveClick}
        formData={formData}
        handleChange={handleChange}
      />
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={confirmDelete}
      />
      <AddResellerModal
        isOpen={isAddResellerModalOpen}
        onClose={() => setAddResellerModalOpen(false)}
        createAuth0User={formData => createAuth0User(formData)}
        refreshResellers={async () =>
          fetchResellers(await getAccessTokenSilently())
        }
      />
    </div>
  );
};

export default Admin;
